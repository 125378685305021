import CountryStates from 'js/CountryStates'

window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.advanced_search.index')) {
    new CountryStates(
      document.querySelector('select[name="country"]'),
      document.querySelector('select[name="state"]'),
      document.querySelector('input[name="state"]')
    ).connectCountryAndState();
  }
});
