import CountryStates from 'js/CountryStates'
import customSelect from 'custom-select';

window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.children-fields')) {
    document.querySelector('.add_fields').addEventListener('click', function(event) {
      event.preventDefault();
      const time = new Date().getTime();
      const regexp = new RegExp(this.dataset.id, 'g');
      document.querySelector('.children-section').insertAdjacentHTML('beforeend', this.dataset['fields'].replace(regexp, time));

      const row = document.querySelector('.children-section').lastChild
      addRemoveFieldListener(row.querySelector('.remove-child'));

      const customSelects = customSelect(row.querySelectorAll('select.custom-select'));
      customSelects.forEach(initCustomSelect);

      if (document.querySelectorAll('.children-section .child-row').length === 4) {
        event.target.style.display = 'none';
      }
    });

    const addRemoveFieldListener = (el) => {
      el.addEventListener('click', function(event) {
        const row = this.closest('.child-row')
        if (row.querySelector('input[type="hidden"]')) {
          row.querySelector('input[type="hidden"]').value = 1;
          row.classList.add('hidden');
        } else {
          row.remove();
        }
        return event.preventDefault();
      });
    }

    document.querySelectorAll('.child-row .remove-child').forEach((button) => {
      addRemoveFieldListener(button);
    });
  }
});
