import braintree from 'braintree-web';
import paymentAuthorized from 'js/payment';

const googleEnv = "TEST";

class GooglePayButton {
  constructor (clientInstance, form) {
    this.clientInstance = clientInstance;
    this.form = form;
  }

  setGoogleBillingAddress(billingAddress, form) {
    form.querySelector('.new-card').disabled = true;
    const [firstName, ...lastName] = billingAddress.name.split(' ').filter(Boolean);
    form.querySelector('.billing-address').disabled = false;
    form.querySelector('.billing-address #address').value = billingAddress.address1;
    form.querySelector('.billing-address #address_cont').value = billingAddress.address2;
    form.querySelector('.billing-address #city').value = billingAddress.locality;
    form.querySelector('.billing-address #country').value = billingAddress.countryCode.toLowerCase();
    form.querySelector('.billing-address select#state').disabled = true;
    form.querySelector('.billing-address input#state').disabled = false;
    form.querySelector('.billing-address input#state').value = billingAddress.administrativeArea;
    form.querySelector('.billing-address #postal_code').value = billingAddress.postalCode;
    form.first_name.value = firstName;
    form.last_name.value = lastName.join(' ');
  };

  handleButton() {
    const googlePayButtonContainer = this.form.querySelector('#google_pay_button_container');
    if (googlePayButtonContainer) {
      const paymentsClient = new google.payments.api.PaymentsClient({
        environment: googleEnv
      });
      braintree.googlePayment.create({
        client: this.clientInstance,
        googlePayVersion: 2,
        // googleMerchantId: 'merchant-id-from-google' // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
      }, (googlePaymentErr, googlePaymentInstance) => {

        paymentsClient.isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
        }).then((response) => {
          if (response.result) {
            document.querySelector('.google-pay-radio-button').classList.remove('hidden');

            const button = paymentsClient.createButton({
              buttonColor: 'white',
              buttonType: 'buy',
              buttonLocale: googlePayButtonContainer.dataset.locale,
              onClick: (event) => {
                event.preventDefault();

                var paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                  transactionInfo: {
                    currencyCode: 'USD',
                    totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
                  }
                });

                // See all available options at https://developers.google.com/pay/api/web/reference/object
                var cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
                cardPaymentMethod.parameters.billingAddressRequired = true;
                cardPaymentMethod.parameters.billingAddressParameters = {
                  format: 'FULL',
                  phoneNumberRequired: true
                };

                paymentsClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
                  googlePaymentInstance.parseResponse(paymentData, (err, result) => {
                    if (err) {
                      console.error('Error parsing payment data: ', err);
                    }

                    // Tokenization succeeded
                    event.target.setAttribute('disabled', true);

                    const nonce = result.nonce;
                    this.form.querySelector('#payment_nonce').value = nonce;
                    this.form.querySelector('#credit_card').disabled = true

                    this.setGoogleBillingAddress(paymentData.paymentMethodData.info.billingAddress, this.form);
                    paymentAuthorized('Google Pay', googlePayButtonContainer, this.form);
                  });
                }).catch((err) => {
                  console.error('Error loading payment data: ', err);
                  // Handle errors
                });
              },
            });

            googlePayButtonContainer.appendChild(button);
          }
        }).catch((err) => {
          const googlePayRadioButton= this.form.querySelector('.google-pay-radio-button');
          googlePayRadioButton.style.display = 'none';
          console.error('Not ready to pay with Google Pay: ', err);
        });
      });
    }
  }
}

export default GooglePayButton;
