import CountryStates from 'js/CountryStates'
import mapOptions from './mapOptions'

window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.workshop_finder.index')) {
    const countryEl = document.querySelector('select#country');
    const stateSelectEl = document.querySelector('select#state');
    const stateInputEl = document.querySelector('input#state');
    new CountryStates(countryEl, stateSelectEl, stateInputEl).connectCountryAndState();
  }
  if (document.querySelector('.workshop_finder.search')) {
    const mapEl = document.getElementById('map');
    const searchTypeEl = document.querySelector('#search_type');
    const workshopTemplate = document.querySelector('.workshop-template');
    const countryEl = document.querySelector('select#country');
    const postalCodeEl = document.querySelector('#postal_code');
    const trainerTemplate = document.querySelector('.trainer-template');
    const infowindow = new google.maps.InfoWindow({ disableAutoPan : true });
    const map = new google.maps.Map(mapEl, mapOptions);
    const geocoder = new google.maps.Geocoder();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const postalCode = urlParams.get('postal_code');
    const country = urlParams.get('country');
    const address = [postalCode, country].join(', ');
    const markers = [];

    [countryEl, postalCodeEl, searchTypeEl].forEach(el => {
      el.addEventListener('change', (e) => {
        const form = e.target.closest('form');
        form.submit();
      });
    });

    const geocode = () => {
      return geocoder.geocode({ address }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location);
        } else {
          console.error(status);
        }
      });
    }

    const clearMarkers = () => {
      markers.forEach((mark) => {
        mark.setMap(null);
      })
      markers.length = 0;
    }

    const showRegistration = (workshop) => {
      const registration = document.querySelector('.registration-modal');
      registration.classList.remove('hidden');
      document.querySelector('.registration-modal .location').textContent = workshop.location;
      document.querySelector('.registration-modal .date').textContent = workshop.date;
      document.querySelector('.registration-modal .time').textContent = workshop.time;
      document.querySelector('.registration-modal .trainer').textContent = workshop.trainer_name;
      document.querySelector('.registration-modal .cost').textContent = workshop.cost;
      document.querySelector('.registration-modal #workshop_registration_workshop_id').value = workshop.id;
      const form = document.querySelector('.registration-modal form');
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        fetch(form.action, {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          }
        }).then(response =>  response.json()).then((data) => {
          if (data.status === 'success') {
            form.reset();
            document.querySelector('.registration-modal .success').classList.remove('hidden');
            document.querySelector('.registration-modal .error').classList.add('hidden');
          } else {
            document.querySelector('.registration-modal .error').classList.remove('hidden');
          }
        });
      });
    }

    const buildWorkshop = (workshop, workHTML) => {
      workHTML.querySelector('.title').innerHTML = workshop.title;
      workHTML.querySelector('.date').innerHTML = workshop.date;
      workHTML.querySelector('.name').innerHTML = workshop.trainer_name;
      workHTML.querySelector('.cost').innerHTML = workshop.cost;
      workHTML.querySelector('.time').innerHTML = workshop.time;
      workHTML.classList.remove('hidden');
      workHTML.querySelector('.btn-details').addEventListener('click', (e) => {
        e.preventDefault();
        showWorkshopDetails(workshop);
      });
      return workHTML;
    }

    const buildTrainer = (trainer, trainerHTML) => {
      trainerHTML.querySelector('.title').innerHTML = trainer.title;
      trainerHTML.querySelector('.name').innerHTML = trainer.trainer_name;
      trainerHTML.querySelector('.phone').innerHTML = trainer.phone;
      trainerHTML.querySelector('.email').innerHTML = trainer.email;
      trainerHTML.querySelector('.website').innerHTML = trainer.website;
      trainerHTML.classList.remove('hidden');
      trainerHTML.querySelector('.btn-details').addEventListener('click', (e) => {
        e.preventDefault();
        showTrainerDetails(trainer);
      });
      return trainerHTML;
    }

    const updateList = (workshops) => {
      const listEl = document.querySelector('.workshop-list');
      const listHTML = []
      listEl.innerHTML = '';
      workshops.forEach((work) => {
        if (work.type === 'workshop') {
          const workHTML = workshopTemplate.cloneNode(true);
          workHTML.id = `workshop-result-${work.id}`;
          listHTML.push(buildWorkshop(work, workHTML));
        }
        if (work.type === 'trainer') {
          const trainerHTML = trainerTemplate.cloneNode(true);
          trainerHTML.id = `trainer-result-${work.id}`;
          listHTML.push(buildTrainer(work, trainerHTML));
        }
      });
      listEl.append(...listHTML);
    }

    const showWorkshopDetails = (workshop) => {
      document.querySelector('.trainer-details').classList.add('hidden');
      document.querySelector('.workshop-details').classList.remove('hidden');
      document.querySelector('.workshop-details .date').innerHTML = workshop.date;
      document.querySelector('.workshop-details .time').innerHTML = workshop.time;
      document.querySelector('.workshop-details .cost').innerHTML = workshop.cost;
      document.querySelector('.workshop-details .location').innerHTML = workshop.formatted_address;
      document.querySelector('.workshop-details .trainer').innerHTML = workshop.trainer_name;
      document.querySelector('.workshop-details .phone').textContent = workshop.phone;
      document.querySelector('.workshop-details .phone').href = `tel:${workshop.phone}`;
      document.querySelector('.workshop-details a.email').textContent = workshop.email;
      document.querySelector('.workshop-details a.email').href = `mailto:${workshop.email}`;
      document.querySelector('.workshop-details .registration-deadline').innerHTML = workshop.registration_deadline;
      document.querySelector('.workshop-details .payment-options').innerHTML = workshop.payment_options;
      document.querySelector('.workshop-details .additional-info').innerHTML = workshop.additional_info;
      document.querySelector('.workshop-details .trainer-bio').innerHTML = workshop.trainer_bio;
      document.querySelector('.workshop-details .btn-register').addEventListener('click', (e) => {
        e.preventDefault();
        showRegistration(workshop);
      });
    }

    const showTrainerDetails = (trainer) => {
      document.querySelector('.workshop-details').classList.add('hidden');
      document.querySelector('.trainer-details').classList.remove('hidden');
      document.querySelector('.trainer-details .name').innerHTML = trainer.trainer_name;
      document.querySelector('.trainer-details .location').innerHTML = trainer.location;
      document.querySelector('.trainer-details .phone').textContent = trainer.phone;
      document.querySelector('.trainer-details .phone').href = `tel:${trainer.phone}`;
      document.querySelector('.trainer-details .email').textContent = trainer.email;
      document.querySelector('.trainer-details .email').href = `mailto:${trainer.email}`;
      document.querySelector('.trainer-details .website').textContent = trainer.website;
      document.querySelector('.trainer-details .website').href = `//${trainer.website}`;
      document.querySelector('.trainer-details .bio').innerHTML = trainer.trainer_bio;
    }

    const clearDetails = () => {
      document.querySelector('.workshop-details').classList.add('hidden');
      document.querySelector('.trainer-details').classList.add('hidden');
    }

    const getMarkers = (fitBounds = true) => {
      clearMarkers();
      const mapBounds = map.getBounds();
      const southWest = mapBounds.getSouthWest().toJSON();
      const northEast = mapBounds.getNorthEast().toJSON();
      const searchType = searchTypeEl.options[searchTypeEl.selectedIndex].value;
      const params = {
        south_west: Object.values(southWest),
        north_east: Object.values(northEast),
        search_type: searchType
      }
      const markerBounds = new google.maps.LatLngBounds();
      const url = new URL(window.location.origin + '/workshop_finder/markers')
      url.search = new URLSearchParams(params).toString();
      return fetch(url).then(response =>  response.json()).then((data) => {
        const workshops = data;
        if (workshops.length === 0) { return findNearestWorkshop(); }
        updateList(workshops);
        workshops.forEach((work) => {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(work['latitude'], work['longitude']),
            map: map
          });
          markers.push(marker);

          markerBounds.extend(marker.position);

          function showMarker(marker) {
            const builder = {
              workshop: buildWorkshop,
              trainer: buildTrainer
            }[work.type];
            const template = {
              workshop: workshopTemplate,
              trainer: trainerTemplate
            }[work.type];
            const infoHTML = builder(work, template.cloneNode(true));

            return function() {
              infoHTML.classList.add('w-48', 'p-2');
              infoHTML.classList.remove('hidden');
              infowindow.setContent(infoHTML);
              infowindow.open(map, marker);
            }
          }

          google.maps.event.addListener(marker, 'click', showMarker(marker));
          document.getElementById(`${work.type}-result-${work.id}`).addEventListener('click', showMarker(marker));
        });

        if (fitBounds && markers.length > 0) {
          map.fitBounds(markerBounds);
          const zoom = map.getZoom();
          map.setZoom(zoom > 16 ? 16 : zoom);
        }
      });
    }

    document.querySelectorAll('.close-btn').forEach((el) => {
      el.addEventListener('click', (e) => {
        clearDetails();
      });
    });

    document.querySelector('.close-registration-btn').addEventListener('click', (e) => {
      document.querySelector('.registration-modal').classList.add('hidden');
    });

    const findNearestWorkshop = () => {
      const url = new URL(window.location.origin + '/workshop_finder/nearest')
      const searchType = searchTypeEl.options[searchTypeEl.selectedIndex].value;
      const params = {...map.getCenter().toJSON(), ...{ search_type: searchType }};
      url.search = new URLSearchParams(params).toString();
      fetch(url).then(response =>  response.json()).then((data) => {
        if (data.lat && data.lng) {
          map.setCenter(data);
        }
      });
    }

    geocode().then(() => {
      getMarkers().then(() => {
        map.addListener('idle', () => {
          getMarkers(false);
        });
      });
    });
  }
});
