$(function() {
  $flash = $('.flash');

  if ($flash.length) {
    const time1second = 1000;
    const duration = 5 * time1second;
    const timer = window.setTimeout(fadeOutFlash, duration);

    function fadeOutFlash () {
      $flash.fadeOut();
      window.clearTimeout(timer);
    }
  }
});
