window.addEventListener('turbolinks:load' , function() {
  if ($('body.promo_codes.new, body.promo_codes.edit').length > 0) {
    $('input[name="promo_code[account_type]"]').each(function handleAccountTypeChange () {
      $(this).on('change', () => {
        $('#promo_code_credit_count_input').toggle($(this).val() === 'facilitator');
      });
    });

    $('#promo_code_count').on('input', function handleCodeCountInput () {
      $('#promo_code_slug_input').toggle(!($(this).val() > 1));
      $('#promo_code_batch_id_input').toggle($(this).val() > 1);
    });
  }

  if ($('body.promo_codes.index').length > 0) {
    $('#account_type, #status').each(function handleTableControlChange () {
      $(this).on('change', () => {
        $('#promo_code_search').trigger('submit');
      });
    });
  }
});
