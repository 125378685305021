import braintree from 'braintree-web';
import paymentAuthorized from 'js/payment';

class ApplePayButton {
  constructor (clientInstance, form) {
    this.clientInstance = clientInstance;
    this.form = form;
  }

  setAppleBillingAddress(billingContact, form) {
    form.querySelector('.new-card').disabled = true;
    form.querySelector('.billing-address').disabled = false;
    form.querySelector('.billing-address #address').value = billingContact.addressLines[0];
    form.querySelector('.billing-address #address_cont').value = billingContact.addressLines[1] || '';
    form.querySelector('.billing-address #city').value = billingContact.locality;
    form.querySelector('.billing-address #country').value = billingContact.countryCode.toLowerCase();
    form.querySelector('.billing-address select#state').disabled = true;
    form.querySelector('.billing-address input#state').disabled = false;
    form.querySelector('.billing-address input#state').value = billingContact.administrativeArea;
    form.querySelector('.billing-address #postal_code').value = billingContact.postalCode;
    form.first_name.value = billingContact.givenName
    form.last_name.value = billingContact.familyName;
  };

  handleButton() {
    const applePayButton= this.form.querySelector('#apple_pay');
    if (applePayButton) {

      if (!window.ApplePaySession || !ApplePaySession.canMakePayments()) {
        const applePayRadioButton= this.form.querySelector('.apple-pay-radio-button');
        applePayRadioButton.style.display = 'none';
      }

      braintree.applePay.create({
        client: this.clientInstance
      }, (applePayErr, applePayInstance) => {
        if (applePayErr) {
          console.error('Error creating applePayInstance:', applePayErr);
          return;
        }

        applePayButton.addEventListener('click', (event) => {
          event.preventDefault();
          const total = this.form.querySelector('#order_total').value;

          var paymentRequest = applePayInstance.createPaymentRequest({
            total: {
              label: 'Prepare Enrich',
              amount: total
            },
            lineItems: [
              {
                label: "Subtotal",
                type: "final",
                amount: total
              },
              {
                label: "Estimated Tax",
                amount: "3.06",
                type: "pending"
              }
            ],
            // We recommend collecting billing address information, at minimum
            // billing postal code, and passing that billing postal code with
            // all Apple Pay transactions as a best practice.
            requiredBillingContactFields: ["postalAddress"]
          });

          const session = new ApplePaySession(3, paymentRequest);

          session.onvalidatemerchant = (event) => {
            applePayInstance.performValidation({
              validationURL: event.validationURL,
              displayName: 'My Store'
            }, (err, merchantSession) => {
              if (err) {
                const errorContainer = document.querySelector('.payment-errors')
                errorContainer.textContent = 'Apple Pay failed to load';
                return;
              }
              session.completeMerchantValidation(merchantSession);
            });
          };

          session.onpaymentauthorized = (event) => {
            applePayInstance.tokenize({
              token: event.payment.token
            }, (tokenizeErr, payload) => {
              if (tokenizeErr) {
                console.error('Error tokenizing Apple Pay:', tokenizeErr);
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                return;
              }

              // Set payload.nonce and display appropriate messaging to user
              const nonce = payload.nonce;
              this.form.querySelector('#payment_nonce').value = nonce;
              this.form.querySelector('#credit_card').disabled = true

              this.setAppleBillingAddress(event.payment.billingContact, this.form);

              paymentAuthorized('Apple Pay', applePayButton, this.form);

              // After you have transacted with the payload.nonce,
              // call `completePayment` to dismiss the Apple Pay sheet.
              session.completePayment(ApplePaySession.STATUS_SUCCESS);
            });
          };

          session.begin();
        });
      });
    }
  }
}

export default ApplePayButton;
