import customSelect from 'custom-select';

window.initCustomSelect = (customSelect) => {
  const { container, opener, select } = customSelect;
  const classList = select.classList;
  classList.remove('custom-select');
  classList.forEach(className => {
    container.classList.add(className);
  });
  const $chevronDown = $('<i class="custom-select-icon fas fa-chevron-down" aria-hidden="true"></i>');
  $(opener).append($chevronDown);

  const fieldset = container.closest('fieldset');

  if (fieldset) {
    customSelect.disabled = fieldset.disabled;
    observeFieldset(fieldset, customSelect);
  }

  if (container.closest('form')) {
    initCustomSelectValidation({ container, select });
  }
}

const initCustomSelectValidation = ({ container, select }) => {
  const validationHTML = document.createElement('span');
  validationHTML.classList.add('custom-select-validation');
  container.parentNode.appendChild(validationHTML);

  select.addEventListener('invalid', () => {
    validationHTML.textContent = select.validationMessage;
  });
  select.addEventListener('change', () => {
    validationHTML.textContent = '';
  });
}

const observeFieldset = (fieldset, customSelect) => {
  // Create a new MutationObserver instance
  const observer = new MutationObserver(mutationsList => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
        customSelect.disabled = fieldset.disabled;
      }
    }
  });

  // Start observing attribute changes on the fieldset
  observer.observe(fieldset, { attributes: true });
}

window.addEventListener('turbolinks:load' , function() {
  const customSelects = customSelect(document.querySelectorAll('select.custom-select'));
  customSelects.forEach(initCustomSelect);
});
