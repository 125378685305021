window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.credits.index')) {
    calculateOrderSummary();
    document.querySelector('input[name="num_credits"]').addEventListener('input', (e) => {
      calculateOrderSummary();
    });
  }
})

const calculateOrderSummary = () => {
  let tax = 0;
  const quantity = document.querySelector('input[name="num_credits"]').value;
  const creditPrices = JSON.parse(document.querySelector('.credits-pricing-table').dataset['creditPrices']);
  const defaultPrice = creditPrices[0][1];
  const price = creditPrices.reverse().find(([minQuantity, _]) => quantity >= minQuantity)[1];
  const creditTotal = defaultPrice * quantity;
  const defaultTotal = (defaultPrice * quantity);
  const bulkSavings = (defaultPrice !== price) ? (defaultPrice - price) * quantity * -1 : 0;
  const taxRate = document.querySelector('.order-summary').dataset['taxRate'];
  const locale = document.querySelector('.order-summary').dataset['locale'] || 'en-US';
  const currencyCode = document.querySelector('.order-summary').dataset['currencyCode'] || 'USD';
  if (taxRate) {
    tax = (creditTotal + bulkSavings) * taxRate;
  }
  const subtotal = creditTotal + bulkSavings;
  const total = creditTotal + bulkSavings + tax;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode
  });

  [
    ['.quantity', quantity],
    ['.credit-cost', formatter.format(defaultTotal)],
    ['.bulk-savings', formatter.format(bulkSavings)],
    ['.js-tax', formatter.format(tax)],
    ['.js-total', formatter.format(total)],
  ].forEach(([selector, content]) => {
    document.querySelector(`.order-summary ${selector}`).textContent = content;
  });
  // update form total
  document.querySelector('input#order_sub_total').value = subtotal;
  document.querySelector('input#order_total').value = total;
};
