window.addEventListener('turbolinks:load' , function() {
  $('.custom-radio input[type=radio]').each(function updateRadio () {
    const $radioGroup = $("input[name='" + this.name + "']");
    $(this).on('change', function handleChange () {
      $radioGroup.not(this).parents('.custom-radio').removeAttr('rel');
      $radioGroup.not(this).parents('.custom-radio').siblings('.conditional-input').val('');
      $(this).parents('.custom-radio').attr('rel', 'checked');
      $(this).parents('.custom-radio').siblings('.conditional-input').val(function setToDefault () {
        return $(this).attr('value') || '';
      });
    });
  });
});
