import _ from 'lodash';

export const paymentAuthorized = (paymentMethod, button, form) => {
  const authorizedMessage = `Authorized with ${paymentMethod}`;
  const messageElement = document.createElement('span');
  const checkbox = document.createElement('i');
  checkbox.classList.add('fas', 'fa-check', 'mr-2');
  messageElement.textContent = authorizedMessage;
  messageElement.prepend(checkbox);
  messageElement.classList.add('flash', 'success');
  button.parentElement.prepend(messageElement);
  button.classList.add('hidden');
  const radioButton= button.closest('form').querySelector(`.${_.kebabCase(paymentMethod)}-radio-button`);
  radioButton.closest('ul').querySelectorAll('input').forEach((input) => {
    if (input.id !== `${_.snakeCase(paymentMethod)}_tab`) {
      input.disabled = true;
    }
  });

  // if credits page
  const creditInput = document.querySelector('#num_credits');
  if (creditInput) {
    creditInput.readOnly = true;
  }

  // don't recalculate tax with new address if billing address already set on facilitator
  if (!document.querySelector('.credits.index') && !document.querySelector('.group_program.pay')) {
    const countryEl = document.querySelector('select#country');
    const stateSelectEl = document.querySelector('select#state');
    const stateInputEl = document.querySelector('input#state');
    const postalCodeEl = document.querySelector('input#postal_code');
    const country = countryEl.value;
    const state = stateSelectEl.value || stateInputEl.value;
    const postalCode = postalCodeEl.value;
    const subtotalEl = document.querySelector('.js-subtotal');
    const subtotal = Number(subtotalEl.textContent.replace(/[^0-9.-]+/g,""));
    calculateTax({country, state, postalCode, subtotal});
  }
};

export const calculateTaxFromElements = (taxElArgs) => {
  const { countryEl, stateSelectEl, stateInputEl, postalCodeEl, subtotalEl, shippingEl } = taxElArgs;
  const country = countryEl.value;
  const state = isVisible(stateInputEl) ? stateInputEl.value : stateSelectEl.value;
  const postalCode = postalCodeEl.value;
  const subtotal = Number(subtotalEl.textContent.replace(/[^0-9.-]+/g,""));
  const shipping = Number(shippingEl.textContent.replace(/[^0-9.-]+/g,""));
  return calculateTax({country, state, postalCode, subtotal, shipping});
}

export const calculateTax = async (taxArgs) => {
  const { country, state, postalCode, subtotal, shipping } = taxArgs;
  if (country.toUpperCase() !== 'US') { return; }
  let taxRate = 0;
  if (state == 'MN' || state == 'WA') {
    return fetch(`/orders/taxes/?country=${country}&state=${state}&postal_code=${postalCode}&amount=${subtotal}&shipping=${shipping}`).then((response) => {
      return response.json();
    }).then((data) => {
      if (!data.error) {
        return updateTax({ subtotal, shipping, amount: data.amount_to_collect, taxRate: data.rate });
      }
    });
  } else {
    return updateTax({ subtotal, shipping, taxRate });
  }
}

const updateTax = (args) => {
  const { subtotal, shipping, taxRate, amount } = args;
  const tax = parseFloat(amount) || parseFloat(subtotal * taxRate);
  const currencyCode = document.querySelector('.order-summary').dataset.currencyCode || 'USD';
  const locale = document.querySelector('.order-summary').dataset.locale || 'en-US';
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });
  document.querySelector('.order-summary').dataset['taxRate'] = taxRate;
  document.querySelector('.order-summary .js-tax').textContent = formatter.format(tax);
  if (shipping !== undefined) {
    document.querySelector('.order-summary .js-shipping').textContent = formatter.format(shipping);
  }

  let total = parseFloat(subtotal) + tax;
  if (shipping) {
    total += shipping;
  }

  document.querySelector('.order-summary .js-total').textContent = formatter.format(total);
  return Promise.resolve();
}

const isVisible = (element) => {
  return element.offsetWidth > 0
      || element.offsetHeight > 0
      || element.getClientRects().length > 0;
}

export default paymentAuthorized;
