const hideOption = { stylers: [ { visibility: "off" } ] };

export default {
    zoom: 13,
    minZoom: 12,
    maxZoom: 13,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: true,
    gestureHandling: true,
    clickableIcons: false,
    styles: [
    {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        ...hideOption,
    },
    {
        featureType: "poi.business",
        ...hideOption,
    },
    {
        featureType: "poi.park",
        elementType: "labels.text",
        ...hideOption,
    },
    {
        featureType: "road.local",
        elementType: "labels",
        ...hideOption,
    }
    ],
};
