import {PhoneNumberUtil,  PhoneNumberFormat as PNF} from 'google-libphonenumber';

window.addEventListener('turbolinks:load', function () {
  if (document.querySelector('.facilitators.setup_profile')) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const countrySelect = document.querySelector('.js-phone-country');
    const phoneInput = document.querySelector('.js-phone-number');
    const phoneErrorEl = document.querySelector('.js-phone-error');
    const form = phoneInput.closest('form');
    const submit = form.querySelector('input[type=submit]');
    let number;

    function handlePhoneInput() {
      if (phoneInput.value.length > 1) {
        number = undefined;
        try {
          number = phoneUtil.parseAndKeepRawInput(phoneInput.value, countrySelect.value);
          if (phoneUtil.isValidNumber(number)) {
            phoneErrorEl.classList.add('hidden');
            submit.removeAttribute('disabled');
            const formattedNumber = phoneUtil.format(number, PNF.INTERNATIONAL);
            phoneInput.value = formattedNumber;
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    handlePhoneInput();
    phoneInput.addEventListener('input', handlePhoneInput);

    form.addEventListener('submit', function validatePhoneNumber(event) {
      let isValidNumber;

      function catchInvalidNumber() {
        phoneErrorEl.classList.remove('hidden');
        event.preventDefault();
      }

      try {
        isValidNumber = phoneUtil.isValidNumber(number);
      } catch (error) {
        catchInvalidNumber();
      }

      if (!isValidNumber) {
        catchInvalidNumber();
      }
    });
  }
});
