window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.orders.cart')) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    const cartInputs = document.querySelectorAll('.quantity-input');
    cartInputs.forEach((input) => {
      input.addEventListener('input', (e) => {
        const inputEl = e.target;
        const quantity = parseInt(inputEl.value);
        const price = parseFloat(inputEl.dataset.price);
        const total = price * quantity;
        const totalEl = inputEl.closest('.line-item-row').querySelector('.line-item-total');
        if (quantity === 0) {
          if (window.confirm('Are you sure you want to delete this item from your cart?')) {
            return updateOrder().then((response) => {
              deleteLineItem(inputEl);
              recalculateTotal();
              recalculateCartItemCount();
            });
          } else {
            return inputEl.value = 1;
          }
        }
        totalEl.innerHTML = formatter.format(total);
        totalEl.dataset.total = total;
        updateOrder().then((response) => {
          recalculateTotal();
          recalculateCartItemCount();
        });
      });
    });

    const deleteLineItem = (inputEl) => {
      const row = inputEl.closest('.line-item-row').remove();
    }

    const updateOrder = () => {
      const orderForm = document.querySelector('.edit_order');
      return fetch(orderForm.action, {
        method: orderForm.method,
        body: new FormData(orderForm),
      });
    }

    const recalculateTotal = () => {
      const lineItemTotals = document.querySelectorAll('.line-item-total');
      const total = Array.from(lineItemTotals).reduce((sum, total) => sum + total.dataset.total, 0)
      const orderTotal = document.querySelector('.order-total');
      orderTotal.innerHTML = formatter.format(total);
    }

    const recalculateCartItemCount = () => {
      const quantityInputs = document.querySelectorAll('.quantity-input');
      const count = Array.from(quantityInputs).reduce((sum, quantityEl) => sum + parseInt(quantityEl.value), 0)
      const cartLinkCount = document.querySelector('.cart-link-count');
      if (count === 0) {
        const lineItemForm = document.querySelector('.edit_order');
        cartLinkCount.parentElement.removeChild(cartLinkCount);
        lineItemForm.parentElement.removeChild(lineItemForm);
      }
      cartLinkCount.innerHTML = count;
    }
  }
});
