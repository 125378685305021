import CountryStates from 'js/CountryStates'
import { calculateTax } from 'js/payment'

window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.orders.shipping_address')) {
    const countryEl = document.querySelector('select#order_shipping_country');
    const stateSelectEl = document.querySelector('select#order_shipping_state');
    const stateInputEl = document.querySelector('input#order_shipping_state');

    new CountryStates(countryEl, stateSelectEl, stateInputEl).connectCountryAndState();
  }

  if (document.querySelector('.orders.shipping_method')) {
    const shippingMethodInputs = document.querySelectorAll('input[name="order[shopify_shipping_handle]"]');
    const subtotalEl = document.querySelector('.order-summary .js-subtotal');

    shippingMethodInputs.forEach(input => {
      input.addEventListener('change', function() {
        if (!this.checked) return;
        const shipping = parseFloat(this.dataset.price);
        const subtotal = parseFloat(subtotalEl.innerText.replace(/[^0-9.-]+/g,""));
        const country = document.querySelector('input#order_shipping_country').value;
        const state = document.querySelector('input#order_shipping_state').value;
        const postalCode = document.querySelector('input#order_shipping_postal_code').value;

        calculateTax({country, state, postalCode, subtotal, shipping});
      });
    });
  }
});
