import CountryStates from 'js/CountryStates'

const countryStatePageSelectors = [
  '.workshops.trainer_application',
  '.trainer_applications.create',
  '.workshops.new',
  '.workshops.create',
  '.workshops.edit',
  '.workshops.update'
];

const autoSubmitPageSelectors = [
  '.workshops.index',
  '.facilitators.edit'
];

window.addEventListener('turbolinks:load' , function() {
  if (countryStatePageSelectors.some(selector => document.querySelector(selector))) {
    const countryEl = document.querySelector('select#country');
    const stateSelectEl = document.querySelector('select#state');
    const stateInputEl = document.querySelector('input#state');

    new CountryStates(countryEl, stateSelectEl, stateInputEl).connectCountryAndState();
  }

  if (autoSubmitPageSelectors.some(selector => document.querySelector(selector))) {
    const form = document.querySelector('form#js-workshop-filter');
    const selects = document.querySelectorAll('form#js-workshop-filter select');
    const initAutoSubmit = (select) => {
      select.addEventListener('change', (e) => {
        form.submit();
      })
    }
    selects.forEach(initAutoSubmit);
  }
});
