window.addEventListener('turbolinks:load' , function() {
  const loadingModalEl = document.querySelector('#loading_modal');
  const loadingModalTrigger = document.querySelector('.js-loading-modal-trigger');
  const loadingModalForm = document.querySelector('.js-loading-modal-form');
  const timeBeforeModalLaunch = 1000; // in milliseconds

  if (loadingModalEl && loadingModalTrigger && loadingModalForm) {
    loadingModalForm.addEventListener('submit', () => {
      const timer = window.setTimeout(() => {
        loadingModalTrigger.dispatchEvent(new Event('click'));
        window.clearTimeout(timer);
      }, timeBeforeModalLaunch);
    });
  }
});
