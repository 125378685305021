window.addEventListener('turbolinks:load' , function() {
  const $dropdowns = $('.dropdown');

  $dropdowns.each(function initDropdown() {
    const $thisDropdown = $(this);
    $thisDropdown.find('.dropdown__button').on('click', function toggleDropdownMenu() {
      $dropdowns.not($thisDropdown).each(closeDropdown);
      $thisDropdown.toggleClass('dropdown--active');
    });
  });

  $(window).on('click', function closeAnyOpenDropdown ({target}) {
    const ancestorDropdowns = $(target).parents().filter(function hasDropdownClassname () {
      return this.classList.contains('dropdown');
    });
    if (ancestorDropdowns.length < 1) {
      $dropdowns.each(closeDropdown);
    }
  });

  function closeDropdown () {
    $(this).removeClass('dropdown--active');
  }
});
