window.addEventListener('turbolinks:load' , function() {
  const form = document.getElementById('js-locale-form');

  if (form) {
    const select = form.querySelector("select#locale");
    const options = form.querySelectorAll('.js-locale-option');
    for (let option, i=0; i<options.length; i++) {
      option = options[i];
      option.addEventListener('click', () => {
        if ('value' in option.dataset) {
          select.value = option.dataset.value;
          form.submit();
        }
      });
    }
  }
});
