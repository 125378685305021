window.addEventListener('turbolinks:load' , function() {
  if (document.querySelector('.couples.index')) {
    const form = document.querySelector('form#js-couples-search');
    const selects = document.querySelectorAll('form#js-couples-search select');
    const initAutoSubmit = (select) => {
      select.addEventListener('change', (e) => {
        form.submit();
      })
    }
    selects.forEach(initAutoSubmit);
  }
});
